import React from 'react';
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';

import SvgIcon from "../../SharedComponents/Icons";
import PlacesSearch from "../../SharedComponents/PlacesSearch";
import NoCommissionBanner from "../../SharedComponents/NoCommissionBanner";

import './index.sass';


const SearchSection = ({ categories, galleryPhotos, regionsOptions }) => {

  const _gallerySlide = (slide) =>
    <div className="image-gallery-image-wrapper">
      <div className="image-gallery-image-filter">

      </div>
      <img alt={slide.address}
           className="image-gallery-image lazyload"
           data-expand="1000"
           data-sizes="auto"
           src={slide.original}
      />
      <div className="container">
        <a href={slide.place_url} className="search-section__gallery-place-info" target="_blank">
          <p>{ slide.name }</p>
          <p><SvgIcon name="map_pin" size={16} />{ slide.address }</p>
        </a>
      </div>
    </div>;

  const renderRightNav = (onClick) => {
    return (
      <div
        className='image-gallery-custom-right-nav'
        onClick={onClick} >
        <SvgIcon name="keyboard-arrow-right-rounded-ios" size={24} />
      </div>
    )
  }

  const renderLeftNav = (onClick) => {
    return (
      <div
        className='image-gallery-custom-left-nav'
        onClick={onClick} >
        <SvgIcon name="keyboard-arrow-left-rounded-ios" size={24} />
      </div>
    )
  };


  return (
    <div className="search-section">

      <ImageGallery
        autoPlay={true}
        items={galleryPhotos}
        infinite={true}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
        slideInterval={7000}
        renderItem={_gallerySlide}
        renderRightNav={renderRightNav}
        renderLeftNav={renderLeftNav}
      />

      <div className="container">
        <h1>Find det perfekte selskabslokale</h1>

        <div className="row">
          <div className="col-12 col-lg-7">
            <PlacesSearch
              categories={categories}
              regionsOptions={regionsOptions}
            />
          </div>
        </div>
      </div>

      <div className="container no-commission-container">
        <NoCommissionBanner />
      </div>
    </div>
  )
};


export default SearchSection;
