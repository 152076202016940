import React from 'react';

import SvgIcon from "../Icons";

import './index.sass';


const NoCommissionBanner = () => {

  return (
    <div className="no-commission">
      <SvgIcon name="no_commission" size={30} />
      <div className="no-commission__text-wrapper">
        <div>Vi tager ingen provision, så det bliver ikke dyrere når du bruger os</div>
      </div>
    </div>
  )
}

export default NoCommissionBanner;
