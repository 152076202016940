import React, { Fragment } from 'react';

import PlaceholderImg from '../../../../src/images/listviewpage/placeholder.jpeg';
import SvgIcon from "../../../SharedComponents/Icons";

import './index.sass';


const Place = ({ hoveredPlaceId, descriptionWidth, place, setHoveredPlaceId }) => {

  const {
    address_line_1,
    category,
    description,
    guest_capacity,
    id,
    label_tag,
    medium_photo_url,
    price_per_seat,
    postal_code,
    postal_name,
    show_label,
    show_promo,
    show_secondary_categories,
    suitable_for_kinds,
    title,
    url,
  } = place;

  const label = show_label && label_tag && label_tag.name;
  const fullAddress = `${ address_line_1 }, ${ postal_code } ${ postal_name }`;

  const handleMouseLeave = () => {
    hoveredPlaceId && hoveredPlaceId === id && setHoveredPlaceId(null);
  };


  return (
    <a className={`place ${show_promo ? 'premium' : ''}`}
       href={url}
       id={`place-${id}`}
       onMouseEnter={() => setHoveredPlaceId && setHoveredPlaceId(id)}
       onMouseLeave={handleMouseLeave}
       target="_blank"
    >

      { label &&
        <div className="place__label">
          { label }
        </div>
      }

      <div className='place__info-wrapper' style={{ width: descriptionWidth}}>

        <div className="place__img-wrapper">
          <img alt={fullAddress}
               className="place__img lazyload"
               data-expand="200"
               data-sizes="auto"
               data-src={medium_photo_url || PlaceholderImg}
          />
        </div>

        { !medium_photo_url &&
          <Fragment>
            <div className="place__img-placeholder-cover" />
            <div className="place__img-placeholder-label">Billede på vej</div>
          </Fragment>
        }

        <h3 className='place__title'>
          { title }
        </h3>
        <div className="place__category">
           { category.name } { show_secondary_categories }
        </div>
        <div className='place__guests-wrapper'>
          { guest_capacity
            ?
              <div className="place__guests-block">
                <SvgIcon name="users2" size={16} />
                { guest_capacity }
              </div>
            :
              <div className="place__address">
                { address_line_1 }
              </div>
          }
        </div>

        { suitable_for_kinds && suitable_for_kinds.length
          ?
          <div className="place__suitable-for-list">
            { suitable_for_kinds.map(kind =>
              <div className="place__suitable-for-list-item" key={`suitable-for-${kind.id}`}>
                <SvgIcon name={kind.id} size={18} />
                { kind.name }
              </div>
            )}
          </div>
          :
            <div className="place__description"
                 dangerouslySetInnerHTML={{ __html: description }}
            />
        }
      </div>

      <div className={`place__price-block border`}>
        <div className="place__price-label">
          Pris
        </div>
        <div className="place__price-value">
          { price_per_seat
            ?
              <Fragment>
                <span>fra</span> { price_per_seat } <span>kr.</span>
              </Fragment>
            :
              <div className="place__price-label as-link">
                Forespørg pris
              </div>
          }
        </div>

      </div>
    </a>
  )
}

export default Place;
